.container__signin {
  position: relative;
  width: 100%;
  background-color: #fff3e7;
  min-height: 100vh;
  overflow: hidden;
}

.container__signin:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #fff3e7 0%, #9b6f43 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form.sign-in-form {
  z-index: 2;
}
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.title {
  font-size: 1.3rem;
  color: #444;
  margin-bottom: 10px;
  text-transform: uppercase;
  /* background: -webkit-linear-gradient(-45deg, #f99a24 0%, #b26628 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 50px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  /* font-weight: 600; */
  font-size: 1rem;
  color: #333;
  background-color: transparent;
}

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}
.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.panel .content {
  color: #fff3e7;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel p {
  font-size: 1.2rem;
  padding: 0.7rem 0;
  /* background: -webkit-linear-gradient(-45deg, #f99a24 0%, #b26628 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}
.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}
.panel .content {
  color: #fff3e7;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 1.2rem;
  padding: 0.7rem 0;
  /* background: -webkit-linear-gradient(-45deg, #f99a24 0%, #b26628 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff3e7;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}
.btn {
  width: 150px;
  /* background-image: linear-gradient(-45deg, #f99a24 0%, #b26628 100%); */
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff3e7;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}
.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}
