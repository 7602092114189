@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto", "Roboto" !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

td {
  font-size: 12px !important;
  /* line-height: 12px !important;
  height: 12px !important; */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #51b52f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #51b52f;
}

.MuiOutlinedInput-root {
  height: 40px !important;
  width: 100% !important;
}

.MuiTextField-root {
  width: 100% !important;
}

.react-datepicker__input-container input {
  background-color: transparent !important;
  width: 100% !important;
  border: 1px solid #bbbec5 !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.MuiTableCell-root {
  border: 1px solid #ddd !important;
}
